import React, { useEffect } from "react";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../includes/header";
import SidebarContent from "../includes/sidebar";
import { getUserDataAPI } from "../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  changeButtonValuesAPI,
  changePasswordAPI,
  getButtonValuesAPI,
  getExposureTableAPI,
} from "../service/AuthService";
import { buttonColors, Encrypt } from "../utils/constants";
import HeaderM from "../includes/header/index-m";
import { getAllCricketMatchListAPI } from "../service/cricket";
import {
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from "../service/soccer&tennis";
const PrivateOutlet = (props) => {
  const userData = useSelector((state) => state.account.userData);
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const [buttons, setButtons] = React.useState({});
  const handleUpdate = (key, value) => {
    setButtons((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpand = (index, sport) => {
    setExpanded((prev) => ({
      ...prev,
      [sport]: {
        isExpanded: !prev[sport]?.isExpanded,
      },
    }));
  };
  const getButtonValues = async () => {
    const { response } = await getButtonValuesAPI();
    setButtons(response);
  };
  const handleSave = async () => {
    const { response, code } = await changeButtonValuesAPI(buttons);
    if (code === 200) {
      toast({
        title: "Success",
        description: "Button values updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    getButtonValues();
  };
  const [matchList, setMatchList] = React.useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const getCricketData = async () => {
    const { response } = await getAllCricketMatchListAPI();
    setMatchList((prev) => ({ ...prev, cricket: response }));
  };
  const getSoccerData = async () => {
    const { response } = await getAllSoccerMatchListAPI();
    setMatchList((prev) => ({ ...prev, soccer: response }));
  };
  const getTennisData = async () => {
    const { response } = await getAllTennisMatchListAPI();
    setMatchList((prev) => ({ ...prev, tennis: response }));
  };
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    getButtonValues();
    getCricketData();
    getSoccerData();
    getTennisData();
  }, []);
  const dispatch = useDispatch();
  const toast = () => {};
  const location = useLocation();
  const isChangePasswordPopOpen = useSelector(
    (state) => state.account.ui.changePasswordPopOpen
  );
  const isChangeButtonPopOpen = useSelector(
    (state) => state.account.ui.changeButtonPopOpen
  );
  const isMyMarketPopOpen = useSelector(
    (state) => state.account.ui.myMarketPopOpen
  );
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [exposureTable, setExposureTable] = React.useState([]);
  const changePassword = async () => {
    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      toast({
        title: "Error",
        description: "Please fill all the fields",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "newPassword and confirm Password does not match",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (newPassword === oldPassword) {
      toast({
        title: "Error",
        description: "New Password and old Password cannot be same",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      toast({
        title: "Error",

        description:
          "newPassword must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    var { data, code, message, response, error } = await changePasswordAPI(
      {
        newPassword: newPassword,
        password: oldPassword,
        resetRequired: false,
      },
      userData.token
    );
    if (code === 200) {
      toast({
        title: "Success",
        description: "New Password changed successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      setTimeout(() => {
        localStorage.clear();
      }, 500);
      window.location.href = "/";
    } else {
      toast({
        title: "Error",
        description: "Wrong Password",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  const getExposureTable = async () => {
    var { response, code } = await getExposureTableAPI();
    if (code === 200) {
      setExposureTable(response);
    }
  };
  useEffect(() => {
    if (userData.token && userData.token !== "") {
      getExposureTable();
      let interval = setInterval(() => {
        getUserData();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [selectedEventForBet]);
  const getUserData = async () => {
    var { response, code, token } = await getUserDataAPI(userData.id);
    if (code === 200) {
      localStorage.setItem("token", token);
      dispatch({
        type: "accountData/setUserProfile",
        payload: response,
      });
    } else {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      dispatch({
        type: "gameData/logOut",
        payload: {},
      });
      // setTimeout(() => {
      //   localStorage.clear();
      //   window.location.href = "/";
      // }, 500);
    }
  };

  if (!userData.token) {
    return (window.location.href = "/");
  }

  return (
    <div className="main-container">
      <div className="sidebar left-sidebar">
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="sidebar-title accordion-header">
              <button
                type="button"
                aria-expanded="true"
                className="accordion-button"
              >
                Racing Sports
              </button>
            </h2>
            <div className="accordion-collapse collapse show">
              <div className="racing-sport accordion-body">
                <ul>
                  <div className="nav-item dropdown">
                    <a
                      id="horse-dropdown"
                      aria-expanded="false"
                      role="button"
                      className="dropdown-toggle nav-link"
                      tabIndex={0}
                      href="#"
                    >
                      Horse Racing
                    </a>
                  </div>
                </ul>
                <ul>
                  <div className="nav-item dropdown">
                    <a
                      id="horse-dropdown"
                      aria-expanded="false"
                      role="button"
                      className="dropdown-toggle nav-link"
                      tabIndex={0}
                      href="#"
                    >
                      Greyhound Racing
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="sidebar-title accordion-header">
              <button
                type="button"
                aria-expanded="true"
                className="accordion-button"
              >
                Others
              </button>
            </h2>
            <div className="accordion-collapse collapse show">
              <div className="other-casino-list accordion-body">
                <ul>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      // href="/casino-page"
                    >
                      <span className="blink_me">Our Casino</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      // href="/international"
                    >
                      <span className="blink_me">Our Virtual</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      // href="/international/avatar"
                    >
                      <span>Avatar</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      //  href="/international/SPRIBE"
                    >
                      <span>Spribe</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      // href="/international/EZUGI"
                    >
                      <span>Ezugi</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      // href="/international/sevenMOJOS"
                    >
                      <span>Seven Mojos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion">
          <div className="accordion-item">
            <h2 className="sidebar-title accordion-header">
              <button
                type="button"
                aria-expanded="true"
                className="accordion-button"
              >
                All Sports
              </button>
            </h2>
            <div className="accordion-collapse collapse show">
              <div className="menu-box accordion-body">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      href="#"
                      data-toggle="dropdown"
                      className="dropdown-toggle nav-link"
                    >
                      <i className="far fa-plus-square me-1" />
                      <span>Politics</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>USA - Presidential Election 2024</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/40/715926745"
                            >
                              <span>USA - Presidential Election 2024</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>Lok Sabha 2024</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/40/573253718"
                            >
                              <span>Lok Sabha 2024</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      data-toggle="dropdown"
                      className="dropdown-toggle nav-link"
                    >
                      <i className="far fa-plus-square me-1" />
                      <span>Cricket</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        {matchList?.cricket?.map((item) => (
                          <a
                            href={"/game/cricket/" + item?.eventId}
                            className=" dropdown-toggle nav-link"
                          >
                            <span>{item?.eventName}</span>
                          </a>
                        ))}
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      id="soccer-dropdown"
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle nav-link"
                    >
                      <i className="far fa-plus-square me-1" />
                      <span>Football</span>
                    </a>
                    <ul
                      aria-labelledby="soccer-dropdown"
                      className={`dropdown-menu `}
                    >
                      {matchList?.soccer?.map((item) => (
                        <a
                          href={"/game/soccer/" + item?.eventId}
                          className=" dropdown-toggle nav-link"
                        >
                          <span>{item?.eventName}</span>
                        </a>
                      ))}
                      {/* <li className="nav-item">
                        {Object.keys(matchList?.soccer)?.map((item) => (
                          <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                              <a
                                data-toggle=""
                                className=" dropdown-toggle nav-link"
                              >
                                <i className="far fa-plus-square me-1" />
                                <span>{item}</span>
                              </a>
                              <ul className="dropdown-menu ">
                                {matchList?.soccer[item]?.map((item) => (
                                  <li className="nav-item">
                                    <a
                                      className=" dropdown-toggle nav-link"
                                      href={"/game-details/1/" + item?.soccerId}
                                    >
                                      <span>{item?.eventName}</span>
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        ))}
                      </li> */}
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      id="tennis-dropdown"
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle nav-link"
                    >
                      <i className="far fa-plus-square me-1" />
                      <span>Tennis</span>
                    </a>
                    <ul
                      aria-labelledby="tennis-dropdown"
                      className={`dropdown-menu `}
                    >
                      {matchList?.tennis?.map((item) => (
                        <a
                          href={"/game/tennis/" + item?.eventId}
                          className=" dropdown-toggle nav-link"
                        >
                          <span>{item?.eventName}</span>
                        </a>
                      ))}
                    </ul>

                    {/* {Object.keys(matchList?.soccer)?.map((item) => (
                      <ul className="dropdown-menu ">
                        <li className="nav-item">
                          <a
                            data-toggle="dropdown"
                            className=" dropdown-toggle nav-link"
                          >
                            <i className="far fa-plus-square me-1" />
                            <span>{item}</span>
                          </a>
                          <ul className="dropdown-menu ">
                            {matchList?.soccer[item]?.map((item) => (
                              <li className="nav-item">
                                <a
                                  className=" dropdown-toggle nav-link"
                                  href={"/game-details/1/" + item?.soccerId}
                                >
                                  <span>{item?.eventName}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    ))} */}

                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>PARAGUAY LNB</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/815208956"
                            >
                              <span>CA Ciudad Nueva - Sol de America</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/521837504"
                            >
                              <span>Felix Perez Cardozo - Olimpia Kings</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/567685150"
                            >
                              <span>Libertad - Deportivo San Jose</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL FPB</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/884000733"
                            >
                              <span>Osasco - Sao Jose</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>JORDAN Championship U20</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/694556834"
                            >
                              <span>Al Jalil U20 - Amman United U20</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/531707253"
                            >
                              <span>Gaza Hashim U20 - Orthodox U20</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>WORLD Club Friendly</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/15/750263696"
                            >
                              <span>Mons-Hainaut - Denain ASC Voltaire</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Volleyball</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>WORLD Pan-American Cup Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/670993415"
                            >
                              <span>Argentina W - USA W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/795300861"
                            >
                              <span>Chile W - Cuba W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/475356671"
                            >
                              <span>Dominican Republic W - Canada W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/868966352"
                            >
                              <span>Mexico W - Colombia W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/494305879"
                            >
                              <span>Peru W - Suriname W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/878750226"
                            >
                              <span>Puerto Rico W - Costa Rica W</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>WORLD Pan-American Cup Final Six</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/468598510"
                            >
                              <span>Canada - Cuba</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/881027318"
                            >
                              <span>Dominican Republic - Mexico</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/576248518"
                            >
                              <span>USA - Puerto Rico</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>ASIA West Asian Championship U20</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/610916932"
                            >
                              <span>Syria U20 - Saudi Arabia U20</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COSTA RICA Primera Division</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/832241030"
                            >
                              <span>UNED - ADV San Jose</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL Paulista U21</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/878129118"
                            >
                              <span>
                                Semelb Transform U21 - Santo Andre U21
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/736496344"
                            >
                              <span>Volei Renata U21 - Praia Grande U21</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL RDJ U21 Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/501750866"
                            >
                              <span>Ad Marica W U21 - Cr Flamengo U21 W</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/463227114"
                            >
                              <span>
                                Tijuca Tenis Clube U21 W - Fluminense U21 W
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>WORLD Club Friendly</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/619201127"
                            >
                              <span>
                                Jastrzabski Wegiel - MKS Banimex Bedzin
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COSTA RICA Primera Division Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/760936483"
                            >
                              <span>UNED W - ADV San Jose W</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL Paulista U19 Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/466197623"
                            >
                              <span>Barueri Sp U19 W - Vinhedo W U19</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/902225105"
                            >
                              <span>
                                Louveira W U19 - Santos Praia Grande U19 W
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>JORDAN Premier League Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/813629877"
                            >
                              <span>Al Taawon W - Al Naqaa W</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>ARGENTINA Super Primera Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/18/777220441"
                            >
                              <span>
                                Universitario De La Plata W - club italiano W
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Snooker</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>CHINA Grand Prix</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/59/715014264"
                            >
                              <span>David Gilbert - Mark Selby</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/59/602411824"
                            >
                              <span>Kyren Wilson - Jimmy Robertson</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Ice Hockey</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>E Games</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>LEAGUE OF LEGENDS - EMEA Masters (Europe)</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/764417874"
                            >
                              <span>GO - Vitality.Bee</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COUNTER - STRIKE 2 - European Pro League</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/831420185"
                            >
                              <span>Dynamo Eclot - Permitta Esports</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COUNTER-STRIKE 2- CCT Europe Series</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/554249769"
                            >
                              <span>grannys knockers - ALTERNATE aTTaX</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/528373492"
                            >
                              <span>Illuminar - Young Ninjas</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>
                            COUNTER-STRIKE Blast Premier Fall (Europe)
                          </span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/909488521"
                            >
                              <span>Complexity - Metizport</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/522614718"
                            >
                              <span>Team Falcons - m80</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/862280836"
                            >
                              <span>Virtus.pro - Zero Tenacity</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/480939391"
                            >
                              <span>Virtus.pro - Zero Tenacity</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COUNTER - STRIKE 2 - Elisa Invitational</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/832274837"
                            >
                              <span>ALTERNATE aTTaX - Team Sampi</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>DOTA 2 - CCT Series</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/496780215"
                            >
                              <span>
                                Natus Vincere Junior - Kings Dragon Esports
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COUNTER - STRIKE 2 - CCT Europe Series</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/544944399"
                            >
                              <span>Revenant Esports - Alliance</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>
                            COUNTER - STRIKE 2 - ESL Challenger League NA
                          </span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/494742576"
                            >
                              <span>Legacy - Mythic</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>LEAGUE OF LEGENDS - LCK (SOUTH KOREA)</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/11/795840681"
                            >
                              <span>Dplus KIA - Fearx</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Futsal</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>COSTA RICA Premier Liga Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/914987613"
                            >
                              <span>Escazu W - UCR W</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BOLIVIA Liga Nacional</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/717791456"
                            >
                              <span>Club Singapur - Proyecto Redag</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/822739012"
                            >
                              <span>Nantes - Club Cordova Potosi</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL LNF</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/577965892"
                            >
                              <span>SC Corinthians Paulista - Brasilia</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>WORLD Friendly International</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/737607954"
                            >
                              <span>Costa Rica - Ukraine</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/472054053"
                            >
                              <span>Portugal - Cuba</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>ARGENTINA Super Cup Women</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/659445870"
                            >
                              <span>All Boys W - Racing Club W</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>GUATEMALA LNV</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/496677336"
                            >
                              <span>Autofix Yantarni - Juridicos</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BRAZIL Liga Paulista</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/9/799519834"
                            >
                              <span>Pinda Futsal - Impacto</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Handball</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Kabaddi</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Golf</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Rugby League</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Boxing</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>UFC</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/6/588158061"
                            >
                              <span>Price N. - Morono A.</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Beach Volleyball</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Mixed Martial Arts</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>UFC Matches</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/627434732"
                            >
                              <span>Angela Hill - Tabatha Ricci</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/462150084"
                            >
                              <span>Cong Wang - Victoria Leonardo</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/709547628"
                            >
                              <span>Edmen Shahbazyan - Meerschaert G.</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/588038421"
                            >
                              <span>Francis Marshall - Dennis Buzukia</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/653505961"
                            >
                              <span>Jacqueline Cavalcanti - Josiane Nunes</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/717512313"
                            >
                              <span>James Llontop - Viecheslav Borshchev</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/769321051"
                            >
                              <span>Jared Cannonier - Caio Borralho</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/591293217"
                            >
                              <span>Michael Morales - Magny N.</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/656849102"
                            >
                              <span>Ryan Loder - Robert Valentin</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/3/879944685"
                            >
                              <span>Zachary Reese - Jose Daniel Medina</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>MotoGP</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Chess</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>Virtual Cricket League</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/virtual-cricket/17/809292211"
                            >
                              <span>India T10 v West Indies T10</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/virtual-cricket/17/626483607"
                            >
                              <span>Pakistan T10 v West Indies T10</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>Abu Dhabi Counties Super Cup</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/17/642055888"
                            >
                              <span>
                                Warwickshire ccc v Nottinghamshire CCC
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>World Super T10 League</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/17/585183554"
                            >
                              <span>Leaping Leopards v Raging Rhinos</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Cycling</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Motorbikes</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Athletics</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Basketball 3X3</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Sumo</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Virtual sports</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Motor Sports</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Baseball</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Rugby Union</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Darts</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>American Football</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>USA NFL - Pre-season</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/538058528"
                            >
                              <span>
                                Atlanta Falcons - Jacksonville Jaguars
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/852891058"
                            >
                              <span>Buffalo Bills - Carolina Panthers</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/497328638"
                            >
                              <span>
                                Cincinnati Bengals - Indianapolis Colts
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/626720416"
                            >
                              <span>Detroit Lions - Pittsburgh Steelers</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/544534870"
                            >
                              <span>Green Bay Packers - Baltimore Ravens</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/643569339"
                            >
                              <span>Houston Texans - Los Angeles Rams</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/511598560"
                            >
                              <span>Kansas City Chiefs - Chicago Bears</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/742399171"
                            >
                              <span>
                                Las Vegas Raiders - San Francisco 49ers
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/775385861"
                            >
                              <span>
                                Philadelphia Eagles - Minnesota Vikings
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/766405213"
                            >
                              <span>Tampa Bay Buccaneers - Miami Dolphins</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>CANADA CFL</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/744207933"
                            >
                              <span>
                                Toronto Argonauts - Saskatchewan Roughriders
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/58/779020833"
                            >
                              <span>
                                Winnipeg Blue Bombers - Hamilton Tiger-Cats
                              </span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Soccer</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Esports</span>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="dropdown-toggle nav-link">
                      <i className="far fa-plus-square me-1" />
                      <span>Boat Racing</span>
                    </a>
                    <ul className="dropdown-menu ">
                      <li className="nav-item">
                        <a className=" dropdown-toggle nav-link">
                          <i className="far fa-plus-square me-1" />
                          <span>BOAT RACING</span>
                        </a>
                        <ul className="dropdown-menu ">
                          <li className="nav-item">
                            <a
                              className=" dropdown-toggle nav-link"
                              href="/game-details/67/562615827"
                            >
                              <span>70TH NEHRU TROPHY BOAT RACE 2024</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
      {props.children}
    </div>
  );
};

export default PrivateOutlet;
