import React from "react";
import { unSetteledBetsAPI } from "../../service/AuthService";
import moment from "moment";

export default function UnSetteledBet() {
  const [betType, setBetType] = React.useState("MATCHED");
  const [data, setData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(1);
  const getUnsetteledBets = async () => {
    const { response, code } = await unSetteledBetsAPI({
      betType,
      pageSize,
      pageNumber,
    });
    if (code == 200) {
      setData(response);
    }
  };
  React.useEffect(() => {
    getUnsetteledBets();
  }, [betType, pageSize]);
  const isMobile = window.innerWidth < 786;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={`col-12 report-main-content m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Un-Setteled Bet</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5 unsetteledbet">
          <div className="row row5">
            <div className="col-12">
              <div id="match_unmatched_delete" role="radiogroup" tabIndex={-1}>
                <div className="custom-control custom-control-inline custom-radio">
                  <input
                    id="matched"
                    type="radio"
                    name="match_unmatched_delete"
                    autoComplete="off"
                    defaultValue={1}
                    className="custom-control-input"
                    value={betType}
                    onClick={() => setBetType("MATCHED")}
                    checked={betType == "MATCHED"}
                  />
                  <label htmlFor="matched" className="custom-control-label">
                    <span>Matched</span>
                  </label>
                </div>
                <div className="custom-control custom-control-inline custom-radio">
                  <input
                    id="unmatched"
                    type="radio"
                    name="match_unmatched_delete"
                    autoComplete="off"
                    defaultValue={2}
                    className="custom-control-input"
                    checked={betType == "UNMATCHED"}
                    onClick={() => setBetType("UNMATCHED")}
                  />
                  <label htmlFor="unmatched" className="custom-control-label">
                    <span>Un-Matched</span>
                  </label>
                </div>
                <div className="custom-control custom-control-inline custom-radio">
                  <input
                    id="deleteed"
                    type="radio"
                    name="match_unmatched_delete"
                    autoComplete="off"
                    defaultValue={3}
                    className="custom-control-input"
                    checked={betType == "DELETED"}
                    onClick={() => setBetType("DELETED")}
                  />
                  <label htmlFor="deleteed" className="custom-control-label">
                    <span>Deleted</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row  row5 mt-2">
            <div className={isMobile ? "col-12 " : "col-2"}>
              <label
                style={{
                  marginLeft: "10px",
                }}
                className="col-form-label"
              >
                Size
              </label>
              <div className={isMobile ? "col-12" : "col-5"}>
                <select
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                  className="form-control form-control-sm"
                  value={pageSize}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={10}
                  className="table b-table table-bordered"
                  id="__BVID__97"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Event Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-center"
                      >
                        Nation
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-center"
                      >
                        Event Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-center"
                      >
                        Market Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={6}
                        className="text-center"
                      >
                        Lay/Back
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={7}
                        className="text-center"
                      >
                        Rate
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={8}
                        className="text-right"
                      >
                        Amount
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={9}
                        className="text-center"
                      >
                        Place Date
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={10}
                        className=""
                      >
                        Match Date
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {data?.content?.map((item, index) => (
                      <tr role="row" key={index}>
                        <td role="cell" className="text-left">
                          {item.event_name}
                        </td>
                        <td role="cell" className="text-center">
                          {item.runner_name}
                        </td>
                        <td role="cell" className="text-center">
                          {item.event_type}
                        </td>
                        <td role="cell" className="text-center">
                          {item.market_type}
                        </td>
                        <td
                          role="cell"
                          className="text-center"
                          style={{
                            color: item.lay_run == "0" ? "#72bbef " : "#faa9ba",
                          }}
                        >
                          {item.lay_run == "0" ? item.back_run : item.lay_run}
                        </td>
                        <td role="cell" className="text-right">
                          {item.amount}
                        </td>
                        <td
                          role="cell"
                          className={`text-right ${
                            item.loss ? "text-danger" : "text-sucess"
                          }`}
                        >
                          {item.loss ? "-" + item?.loss : item?.profit}
                        </td>
                        <td role="cell" className="text-center">
                          {moment(item?.created_date)?.format(
                            "DD:MM:YYYY HH:mm:ss"
                          )}
                        </td>
                        <td role="cell" className="text-center">
                          {moment(item?.created_date)?.format(
                            "DD:MM:YYYY HH:mm:ss"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12" />
          </div>
        </div>
      </div>
    </div>
  );
}
